import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import BackupPage from "./Components/BackupPage";
import Intro from "./Components/Intro";
import Phrase from "./Components/Phrase";
import Readme from "./Components/Readme";

function App() {
  return (
    <div className="App overflow-hidden h-screen">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Readme />} />
          <Route path="/intro" element={<Intro />} />
          <Route path="/phrase" element={<Phrase />} />
          <Route path="/backup" element={<BackupPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
