import React, { useState } from "react";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import { RiShieldCheckLine } from "react-icons/ri";
import { NavLink } from "react-router-dom";
const Phrase = () => {
  const [restore, setRestore] = useState(false);

  return (
    <div>
      <div className="bg-white w-screen p-3">
        <div className="md:max-w-[1250px] md:mx-auto flex items-center space-x-5">
          <NavLink to="/intro">
            <MdOutlineArrowBackIosNew className="text-xl font-[600]" />
          </NavLink>
          <p className="text-xl font-[600]">Restore Jaxx Liberty Wallet</p>
        </div>
      </div>
      {restore ? (
        <div className="flex p-2 flex-col items-center space-y-6 w-full">
          <p className="text-white lg:text-md text-sm ">
            This is your 12-word backup phrase. Write them down in the correct
            order and store them in a safe place.
          </p>
          <div className="bg-blue-800 rounded-lg p-4 w-full">
            <ul>
              <li className="font-[600] lg:text-2xl">
                <span className="text-slate-400">1.</span>
                <span className="text-white">dawn</span>
              </li>
              <li className="font-[600] lg:text-2xl">
                <span className="text-slate-400">2.</span>
                <span className="text-white">clown</span>
              </li>
              <li className="font-[600] lg:text-2xl">
                <span className="text-slate-400">3.</span>
                <span className="text-white">orphan</span>
              </li>
              <li className="font-[600] lg:text-2xl">
                <span className="text-slate-400">4.</span>
                <span className="text-white">mistake</span>
              </li>
            </ul>
          </div>
          <button className="bg-[#ee722e]  px-4 py-1 text-white rounded-lg mt-10 w-58 text-xl">
            Next four words
          </button>
        </div>
      ) : (
        <div className="text-white flex flex-col items-center pt-20 md:max-w-[500px] md:mx-auto">
          <p className="text-5xl">
            <RiShieldCheckLine />
          </p>
          <p className="text-2xl md:text-3xl font-[600]">Back Up Your Wallet</p>
          <div className="bg-blue-800 px-5 py-2 mt-4 md:w-full flex flex-col items-center justify-center">
            <p className="text-red-500 font-[600] text-lg text-center">
              We only suggest you backup your wallet
            </p>
            <p className="w-96 text-lg text-center">
              Backing up is the only way to restore your Jaxx. Liberty Wallet if
              your device is lost, stolen, broken or inaccessible
            </p>
          </div>
          <button
            onClick={() => setRestore(true)}
            className="bg-[#ee722e] text-white text-2xl rounded-md md:w-56 w-48 py-2 mt-10"
          >
            Back Up Now
          </button>
          <p className="underline text-lg md:text-xl font-[600] mt-5">
            Skip Now
          </p>
        </div>
      )}
    </div>
  );
};

export default Phrase;
