import React from "react";
import { NavLink } from "react-router-dom";

const Readme = () => {
  return (
    <div className="text-white ">
      <div className="p-4 overflow-y-auto h-[850px] pb-40">
        <div className="md:max-w-[1250px] md:mx-auto">
          <markdown _ngcontent-evj-c6="">
            <h3 id="terms-of-service" className="text-2xl mb-5">
              Terms of Service
            </h3>
            <p className="text-2xl mb-5 mt-2" id="jaxx">
              Jaxx
            </p>
            <p className="mt-3 text-lg">
              Decentral Inc. (“Decentral”) is the maker of a software program
              that can be used to manage, control, and interact with many
              different public blockchains (known as “Jaxx Liberty” or “Jaxx”
              but referred to as “Jaxx” in this agreement). This agreement is a
              contract between Decentral and “You” (or “Your”), a user of Jaxx.
              By entering into this agreement You shall have permission to use
              Jaxx for Your own personal use, subject to certain rights,
              responsibilities, and obligations.
            </p>
            <p id="you-control-your-wallet " className="text-2xl my-5">
              You Control Your Wallet
            </p>
            <p className="text-lg mb-5">
              Jaxx is a multi-blockchain software application that allows You to
              create and manage many different “wallets”, all of which are
              derived from a “Backup Phrase” (i.e. 12 word seed) supplied by You
              or generated through Jaxx. You must safeguard Your Backup Phrase
              (including making offline backup copies) as it is used to derive
              the many private keys that control the “digital assets” (e.g.
              Bitcoin, Ether, Litecoin, etc.) that You may use with Jaxx.
            </p>
            <p>
              Jaxx does not have accounts (or passwords) because it has
              different operating principles from most software programs,
              primarily driven by the security features offered by Jaxx. There
              are important consequences to not having accounts such as not
              being able to use a “forgot password” feature. You should take
              care to become familiar with how Jaxx (and other blockchain wallet
              software) works before doing non-trivial transactions with it.
              Decentral provides many educational resources that can be found at{" "}
              <a
                class="jx-text-link"
                href="https://jaxx.io"
                rel="noopener noreferrer"
                target="_blank"
              >
                {" "}
                https://jaxx.io{" "}
              </a>
              .
            </p>
            <p>
              When You make use of Jaxx You are taking control of Your digital
              assets. Decentral has no technical ability to interact with Your
              digital wallets and does not ever take possession, control, or own
              what belongs to You (i.e. Your digital assets such as bitcoins or
              ERC-20 tokens).
            </p>
            <p className="text-2xl mb-5 mt-2" id="your-responsibilities">
              Your Responsibilities
            </p>
            <p>
              Jaxx is designed to empower its users and that means that You must
              take responsibility for certain actions, including:
            </p>
            <ol>
              <li>
                securing Your Backup Phrase (because Decentral cannot “recover”
                it and never has access to it); and,
              </li>
              <li>
                taking care not to overwrite or delete Jaxx without backing up
                Your Backup Phrase; and,
              </li>
              <li>
                ensuring that Your devices that You use Jaxx on are free of
                viruses, malware, etc.; and,
              </li>
              <li>obeying Your local laws; and,</li>
              <li>
                obeying the laws of Canada, where Decentral is based; and,
              </li>
              <li>using Jaxx in a lawful manner; and,</li>
              <li>
                ensuring that all of Your transactions are done with correct
                blockchain addresses; and,
              </li>
              <li>
                keeping Your Backup Phrase confidential and not sharing it with
                anyone else, including Jaxx customer support (who will never ask
                You for Your Backup Phrase).
              </li>
            </ol>
            <p className="text-2xl mb-5 mt-2" id="your-privacy-is-important">
              Your Privacy Is Important
            </p>
            <p>
              Jaxx is designed to protect Your privacy when interacting with
              blockchains. Your Backup Phrase and private keys are never
              communicated to Decentral but certain data from Jaxx is
              transmitted to Decentral, including telemetry data and logs that
              may contain error codes or error descriptions, used to improve the
              application experience and solve technical problems. Decentral
              will never share or sell Your personal information to advertisers,
              and takes care to minimize logging and access to telemetry data
              within Decentral.
            </p>
            <p>
              To make the most out of Jaxx, permission to access the camera on
              Your mobile device may be requested. Camera device permissions are
              only used for recognizing “QR codes” that are commonly used to
              embed blockchain information into a machine readable format.
              Images from Your camera will never be transmitted to Decentral but
              the data contained within the QR Codes may be used as part of
              transactions that You undertake, which may be passed to Decentral
              as part of broadcasting Your blockchain transactions.
            </p>
            <p className="text-2xl mb-5 mt-2" id="blockchains-have-risks">
              Blockchains Have Risks
            </p>
            <p>
              There are many risks associated with digital assets. Decentral has
              no control over the blockchains that You can interact with through
              Jaxx and cannot (and has no ability to) cancel, modify or affect
              any transaction that You have caused to be broadcasted to any
              given blockchain network. You should take care to understand the
              details of the blockchains You choose to interact with as
              Decentral has no control or liability to You in relation to these
              risks.
            </p>
            <p className="text-2xl mb-5 mt-2" id="license-to-you">
              License to You
            </p>
            <p>
              Jaxx is licensed to You on a personal, non-exclusive,
              non-transferrable, non-assignable, royalty-free, worldwide basis.
              Notwithstanding the foregoing, Jaxx includes many open-source
              software libraries that are separately licensed and the license in
              this agreement does not cover the third-party open-source software
              that is bundled with Jaxx.
            </p>
            <p className="text-2xl mb-5 mt-2" id="no-warranty">
              No Warranty
            </p>
            <p>
              Jaxx is provided at no cost to You, "as is". Decentral makes no
              guarantees that its software will always will be safe, secure, or
              error-free, or that it will function without disruptions, delays,
              or imperfections. To the extent permitted by law, Decentral
              DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING
              IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
              PURPOSE, TITLE, AND NON-INFRINGEMENT.
            </p>
            <p
              className="text-2xl mb-5 mt-2"
              id="termination-or-suspension-of-service-for-unlawful-conduct"
            >
              Termination or Suspension of Service for Unlawful Conduct
            </p>
            <p>
              Although Jaxx is installable software, it also contains elements
              that rely on backend infrastructure run by Decentral (and possibly
              other parties) that may be turned off temporarily or permanently
              without notice due to suspected unlawful conduct or if it is
              deemed necessary to do so at the discretion of Decentral.
            </p>
            <p>
              Some or all of the Jaxx service may not be available in all
              locations worldwide due to geoblocking or other measures taken by
              Decentral to ensure compliance with laws or to improve the quality
              of service for its users.
            </p>
            <p className="text-2xl mb-5 mt-2" id="myjaxx">
              myJAXX
            </p>
            <p>
              Jaxx includes a feature named “myJAXX” that allows You to opt-in
              to certain optional features. If You choose to make use of myJAXX
              by registering then You may be disclosing personal information to
              Decentral that will be kept confidential, to the extent permitted
              by law. Further rules, obligations, and responsibilities may apply
              to Your myJAXX account and You may be contacted via email to
              inform You of changes to Jaxx/myJAXX or opportunities to make use
              of myJAXX features.
            </p>
            <p className="text-2xl mb-5 mt-2" id="third-party-services">
              Third-Party Services
            </p>
            <p>
              Jaxx includes functionality that You have the option of using that
              may involve third-party services that are owned and run by
              companies or organizations not related to Decentral. There may be
              additional terms or rules applicable to third-party services
              available through Jaxx, some of which may involve registration(s)
              on websites or through applications not controlled by Decentral,
              and Decentral shall not be liable for any conduct, actions, or
              negative consequences that arise as a result of Your use of
              third-party services in connection with Jaxx.
            </p>
            <p>
              Notwithstanding the generality of the foregoing, if You make use
              of third-party services that enable digital asset transfers or
              financial transfers then You may be at risk of loss due to
              malfunctions or other issues with third-party services. You shall
              indemnify Decentral, and the officers, contractors, employees, and
              other personnel of Decentral, against any claims in relation to
              third-party services, whether brought by You or another party,
              where the claim concerns Your use or misuse of a third-party
              service.
            </p>
            <p className="text-2xl mb-5 mt-2" id="limitation-of-liability">
              Limitation of Liability
            </p>
            <p>
              Jaxx is offered to You at no charge and therefore Decentral’s
              liability to You must be and is limited, in order to continue
              offering Jaxx in a competitive market. Therefore, and
              notwithstanding any term of this agreement, Decentral’s liability
              to You, under any theory of law or cause of action, shall be
              limited to the Canadian dollar value of 0.01 bitcoins or $50
              Canadian Dollars, whichever is greater. Furthermore, in no event
              shall Decentral be liable for failed transactions, delayed relay
              of transactions, lost Backup Phrases, lost private keys, errors in
              the underlying software libraries that make up Jaxx, lost profits,
              changes in the values of fiat currencies, digital assets or
              cryptocurrencies, or any other losses related to Your use or
              misuse of Jaxx in combination with digital assets.
            </p>
            <p
              className="text-2xl mb-5 mt-2"
              id="accuracy-of-in-app-information"
            >
              Accuracy of In-App Information
            </p>
            <p>
              Jaxx integrates many third-party data sources that provide
              relevant data to users but this data is beyond Decentral’s
              control. Decentral shall not have any liability to You for
              incorrect data and You should verify any data You receive through
              Jaxx before acting on it.
            </p>
            <p className="text-2xl mb-5 mt-2" id="user-support">
              User Support
            </p>
            <p>
              Decentral offers free technical support to users of Jaxx but this
              support is offered to You without warranty of any kind and shall
              be at Your risk. Decentral may choose to discontinue offering free
              technical support at any time and may choose to discontinue
              providing You with technical support at any time. You shall at all
              times act respectfully to Decentral’s staff and must take care not
              to accidentally disclose Your Backup Phrase, private keys, or any
              other confidential information.
            </p>
            <p className="text-2xl mb-5 mt-2" id="amendment">
              Amendment
            </p>
            <p>
              Decentral may amend this agreement by providing You with 15 days
              notice. If You do not consent to an amendment of this agreement
              then Your sole remedy shall be to discontinue using Jaxx. You may
              not amend this agreement.
            </p>
            <p
              className="text-2xl mb-5 mt-2"
              id="choice-of-forum-and-governing-law"
            >
              Choice of Forum and Governing Law
            </p>
            <p>
              Any disputes related to this agreement shall be heard in the
              courts of the City of Toronto, Ontario, Canada and the laws of
              Ontario, Canada shall apply.
            </p>
            <p className="text-2xl mb-5 mt-2" id="entire-agreement">
              Entire Agreement
            </p>
            <p>
              This agreement is the entire agreement between the parties and
              supersedes any other representations or agreements between the
              parties. Furthermore, the parties have entered into this agreement
              without relying on any representations or warranties made outside
              of this agreement.
            </p>
          </markdown>
        </div>
      </div>

      <div className="fixed bottom-0 bg-[#3E66B2] flex items-center flex-col w-full p-6">
        <p>By clicking “I Agree”, you agree to Jaxx</p>
        <p>Liberty's Terms of Service</p>
        <NavLink to="/intro">
          <button className="bg-[#ee722e] p-3 rounded-lg mt-5 w-40">
            I Agree
          </button>
        </NavLink>
      </div>
    </div>
  );
};

export default Readme;
