import React, { useState } from "react";
import { AiFillCamera } from "react-icons/ai";
import {
  MdOutlineArrowBackIosNew,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
import { NavLink } from "react-router-dom";
import ImportWallet from "./ImportWallet";

const BackupPage = () => {
  const [restore, setRestore] = useState(false);

  return (
    <div className="">
      <div className="bg-white w-screen flex items-center p-3 space-x-5">
        <NavLink to="/intro">
          <MdOutlineArrowBackIosNew className="lg:text-xl text-lg font-[600]" />
        </NavLink>
        <p className="lg:text-xl text-lg font-[600]">Backup Wallet</p>
      </div>
      <div className="flex flex-col items-center text-white px-3 overflow-auto h-screen pb-20">
        <div className="flex flex-col mt-5 text-center space-y-4 items-center text-white">
          <p className="text-2xl font-[600]">Enter Backup Phrase</p>
          <p>
            {" "}
            Type in 12-word backup phrase in the correct order to pair your Jaxx
            Liberty wallet.
          </p>
          {!restore && (
            <button
              onClick={() => setRestore(true)}
              className="bg-[#ee722e] p-3 rounded-lg mt-5 w-58 text-xl"
            >
              Enter Backup Phrase
            </button>
          )}
        </div>
        {restore ? (
          <div className="flex items-center flex-col mt-5">
            <ImportWallet />
            <br />
          </div>
        ) : (
          <>
            <div className="text-white flex items-start space-x-5 my-10">
              <span className="border-b lg:w-40 w-20 text-[#091d43]">.</span>
              <span className="lg:text-2xl text-xl font-[600]">OR</span>
              <span className="border-b lg:w-40 w-20 text-[#091d43]">.</span>
            </div>
            <div className="flex flex-col space-y-5 items-center text-white">
              <button className="p-4 rounded-full text-white bg-blue-400 text-3xl">
                <AiFillCamera />
              </button>
              <p className="lg:text-3xl text-xl font-[600]">
                Scan Backup Phrase QR Code
              </p>
              <div className="text-center flex flex-col items-center">
                <p>The QR code can be found on your other device under</p>
                <p className="flex  items-start">
                  <span>Menu</span>
                  <MdOutlineKeyboardArrowRight className="text-2xl" />
                  <span>Security</span>
                  <MdOutlineKeyboardArrowRight className="text-2xl" />
                  <span>View Backup Phrase</span>
                </p>
              </div>
              <div className="text-center">
                <p className="">
                  Click on the checbox, then click on the View Backup Phrase
                  button
                </p>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default BackupPage;
