import React from "react";
import { NavLink } from "react-router-dom";
import logo from "../Assets/logo.svg";

const Intro = () => {
  return (
    <div className="flex flex-col h-full items-center justify-center space-y-5">
      <div className="flex flex-col items-center">
        <img src={logo} alt="logo" className="w-56" />
        <p className="text-white text-2xl text-center">
          Your gateway to all things crypto
        </p>
      </div>
      <div className="pt-20">
        <NavLink to={"/phrase"}>
          <button className="bg-[#ee722e] text-white text-2xl rounded-md w-64 py-3">
            Get Started
          </button>
        </NavLink>
        <NavLink to={"/backup"}>
          <p className="text-white text-lg text-center underline mt-5">
            Restore Interface
          </p>
        </NavLink>
      </div>
    </div>
  );
};

export default Intro;
